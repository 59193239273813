import { ProductionOrderChangeResource } from '@/resource/model';
import { OrderChangeStatusEnum } from '@/resource/enum/order-change-status';
import { Query } from '@/api/base';
import productionOrderChangeService from '@/api/production-management/production-order-change';
import { OsTable, OsQueryPlan } from '@/components';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { debounce, messageError } from '@/utils';
import { dateFormat } from '@/filters';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import { PageQueryPlanEnum } from '@/resource/enum';

@Component({
  name: 'ProductionOrderChange',
  components: {}
})
export default class ProductionOrderChange extends mixins(PagingMixin, CustomColumnMixin) {
  public permission = true;

  /**
   * 审批弹窗显示控制
   */
  public approvalDialogVisible = false;

  /**
   * 审核
   */
  public btnApprovalLoading = false;

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderChangeResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small'
    // defaultSort: { prop: 'createTime', order: 'descending' }
  };

  public tableToolbarOptions: Array<OperationOption> = [];

  /**
   * 查询条件UI配置
   */
  public queryFormOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'orderChange.status',
      option: {
        placeholder: 'orderChange.selectStatus'
      },
      optionData: productionOrderChangeService.getStatusSelectableList()
    },
    {
      type: 'DateRangePicker',
      field: 'createDate',
      label: 'orderChange.changeDate',
      option: {
        startPlaceholder: 'common.startPlaceholder',
        endPlaceholder: 'common.endPlaceholder',
        placeholder: 'common.inputCreateDate',
        pickerOptions: {
          disabledDate(callbackDateStr: string): Boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();

            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * 查询表单对象
   */
  public queryForm: Query<{
    keywords: string;
    status: OrderChangeStatusEnum | null;
    createDate: Array<string>;
    startDate: string | null;
    endDate: string | null;
  }> = {
    createDate: [],
    endDate: undefined,
    keywords: '',
    startDate: undefined,
    status: undefined
  };
  public defaultQueryForm: Query<{
    keywords: string;
    status: OrderChangeStatusEnum;
    createDate: Array<string>;
    startDate: string | null;
    endDate: string | null;
  }> = {
    createDate: [],
    endDate: undefined,
    keywords: '',
    startDate: undefined,
    status: undefined
  };

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderChangeResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'orderChange.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'orderCode',
      label: 'orderChange.orderCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'orderChange.projectName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'orderChange.customerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'orderChange.status',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'reason',
      label: 'orderChange.changeReason',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'refuseReason',
      label: 'orderChange.rejectReason',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'orderChange.changeMan',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'orderChange.changeTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'confirmTime',
      label: 'orderChange.confirmTime',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.productionOrderChange;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  /**
   * 当前编辑数据对象
   */
  public editRow: ProductionOrderChangeResource | null = null;

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<ProductionOrderChangeResource> = [];

  public activated(): void {
    // if (this.$route.meta!.needReload) {
    //   this.queryClick();
    //   this.$route.meta!.needReload = false;
    // }
    this.queryClick();
  }

  public mounted(): void {
    this.queryClick();
  }

  public created(): void {
    this.initColumns(this.tableColumnOptions, 'productionOrderChange');
  }
  /**
   * 重新加载数据
   */
  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    (this.$refs.tableRef as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }
  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  /**
   * 去单据详情
   * @param rowData 行数据
   */
  public goDetail(rowData: ProductionOrderChangeResource): void {
    this.$router.push({
      path: '/production-order-change-detail',
      query: {
        id: rowData.id.toString()
      }
    });
  }

  /**
   * 检索按钮
   */
  public handleQueryClick(): void {
    this.loadData();
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: OrderChangeStatusEnum): string {
    return productionOrderChangeService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: OrderChangeStatusEnum): string {
    return productionOrderChangeService.getStatusClass(status);
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<ProductionOrderChangeResource>): void {
    this.selectedRows = selectedData;
  }

  @Watch('queryForm.createDate')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startDate = undefined;
      this.queryForm.endDate = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startDate = dateFormat(value[0], 'YYYY-MM-DD');
      this.queryForm.endDate = dateFormat(value[1], 'YYYY-MM-DD');
    }
  }

  /**
   * 数据加载
   */
  private loadData(): void {
    this.tableOption.loading = true;
    productionOrderChangeService
      .getList(this.queryForm as ProductionOrderChangeResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
